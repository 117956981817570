<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-07-26 11:05:21
-->
<template>
  <config-item :label='label'>
    <div  @mouseenter="changeDisabled(true)" @mouseleave ="changeDisabled(false)">
       <el-input
      v-model="cloneValue"
      type="textarea"
      autosize
      v-bind="mOptions"
      :placeholder='mOptions.placeholder'
      size='small'
      @blur="changeValue"
    ></el-input>
    </div>
   
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaString",
  mixins: [schemaMixin],
  data() {
    return {
      cloneValue:''
    };
  },
  created () {
    this.cloneValue=this.$cloneDeep(this.mValue)
  },
  methods: {
    changeDisabled(e){
      this.$emit('changeDisabled',e)
    },
    changeValue(){
      this.mValue=this.cloneValue
    },
  }
};
</script>