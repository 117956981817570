/*
 * @Description: 文章管理api
 * @Autor: WangYuan
 * @Date: 2021-09-22 15:51:56
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-22 16:55:04
 */
import request from '@/utils/request'

/**
 * 查询文章列表
 */
export const getGoodsList = async (data) => request({ url: "goods/getByList", method: "POST", data })
// 查询文章分类
export const getGoodsType = async (params) => request({ url: "/api/admin/v1/cms/archives/channel", method: "GET", params })
// 查询党员分类
export const getMemberType = async (params) => request({ url: "/api/v2/user/communist/types", method: "GET", params })
// 查询领导架构
export const getCommittee = async (params) => request({ url: "/api/admin/v1/setting/user/committee", method: "GET", params })
//查询会员等级
export const getVip = async (params) => request({ url: "/api/admin/v1/setting/user/level", method: "GET", params })
//查询供需分类
export const getSupply = async (params) => request({ url: "/api/admin/v1/supply/type", method: "GET", params })
//查询专家分类
export const getExpert= async (params) => request({ url: "/api/aiscrm/guset/v1_type", method: "GET", params })
//查询产品分类
export const getProduct = async (params) => request({ url: "/api/admin/v1/product/type", method: "GET", params })
//查询专委会分类
export const getBranch = async (params) => request({ url: "/api/admin/v1/store/index/type_list", method: "GET", params })
//查询文件分类
export const getFile = async (params) => request({ url: "/api/pc/file/folder", method: "GET", params })

/**
 * 根据id查询文章详情
 */
export const getGoodsById = async (data) => request({ url: "goods/getById", method: "POST", data })

/**
 * 根据id列表查询文章列表
 */
 export const getGoodsByIds = async (data) => request({ url: "goods/getByIds", method: "POST", data })

/**
 * 新增文章
 */
export const addGoods = async (data) => request({ url: "goods/add", method: "POST", data })

/**
* 编辑文章
*/
export const editGoods = async (data) => request({ url: "goods/edit", method: "POST", data })

/**
* 复制文章
*/
export const copyGoods = async (data) => request({ url: "goods/copy", method: "POST", data })