<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-08-19 15:10:03
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-09-23 14:54:22
-->
<template>
  <div class="flex flex-wrap row-between">
    <el-select v-model="value" @change="changeValue" multiple placeholder="全部" v-if="label=='人物列表'||label=='会员列表'||label=='选择分类'||label=='党员数据'">
      <el-option
        v-for="item in list"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
    <el-select v-model="value" @change="changeValue" multiple placeholder="全部" v-if="label=='供需分类'||label=='产品分类'">
      <el-option
        v-for="item in list"
        :key="item.key"
        :label="item.value"
        :value="item.key">
      </el-option>
    </el-select>
     <!-- <div v-if="value.length>0" class="f12  mt10 mr10">
      <div v-for="(item,index) in value" :key="index" class="type-item pointer">
         {{getName(item)}}
      </div>
    </div>
    <div
       class="f12 f-blue mt10  pointer"
      @click="add"
    >
       {{value.length>0?fullText:emptText}}
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getMemberType,getCommittee,getVip,getSupply,getProduct,getBranch} from "@/api/goods";

export default {
  name: "GoodsConfigList",
  props: ["value","label"],

  data() {
    return {
      mValue: [],
      list: [],
    };
  },

  computed: {
    ...mapGetters(["project"]),
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.getList();
      },
    },
  },

  methods: {
    // 获取人物分类列表
    async getList() {
      let res  
      if(this.label=='人物列表'){
        res  = await getCommittee();
      }
      if(this.label=='党员数据'){
        res  = await getMemberType();
      }
      if(this.label=='会员列表'){
        res  = await getVip();
      }
      if(this.label=='供需分类'){
        res = await getSupply()
      }
      if(this.label=='产品分类'){
        res = await getProduct()
      }
       if(this.label=='选择分类'){
         res = await getBranch()
      }
      if(res.code==200){
        this.list=res.data
      }
      if(res.code==1){
         this.list=res.data
      }
    },
    changeValue(){
      console.log(this.value,'--------0000')
      this.$emit("update",this.value);
    },
    getName(v){
      let name=''
      if(this.label=='人物列表'||this.label=='会员列表'){
        if(this.list.find((item)=> item.id==v)){
          name = this.list.find((item)=> item.id==v).name
        }
      }
     if(this.label=='供需分类'||this.label=='产品分类'){
        if(this.list.find((item)=> item.key==v)){
          name = this.list.find((item)=> item.key==v).value
        }
     }
      return name
    },
    add() {
      this.$emit("edit");
    },
  },
};
</script>
<style scoped>
.type-item{
  display: flex;
  padding: 0px 10px 10px 10px;
}
</style>
