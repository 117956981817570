/*
 * @Description: 项目配置数据
 * @Autor: WangYuan
 * @Date: 2021-08-31 19:57:35
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-12-15 15:29:47
 */

// 固定页面集合
export const fixedPages = [
	{
		id: '/pages/index/index',
		name: '首页',
		type: 'fixed',
	},
	{
		id: '/pagesA/supply/supply',
		name: '供需',
		type: 'fixed',
		need_auth: true,
	},
	{
		id: '/pages/vip/vip',
		name: '权益',
		type: 'fixed',
	},
	{
		id: '/pages/connection/connection',
		name: '人脉',
		type: 'fixed',
	},
	{
		id: '/pages/user/user',
		name: '我的',
		type: 'fixed',
	},
];
export const infoPages = [
	{ id: '/pages/navList/navList?title=more', name: '最新动态', type: 'info' },
	{ id: '/pagesB/activities/activitiesList', name: '活动列表', type: 'info' },
	{ id: '/pages/navList/navList?title=special', name: '专题列表', type: 'info' },
	{ id: '/pagesA/address/addressManage', name: '个人资料', type: 'info' },
	{ id: '/pagesA/edit/edit', name: '我的收藏', type: 'info' },
	{ id: '/pagesA/index/search', name: '搜索页', type: 'info' },
	{ id: '/pagesA/user/certificate', name: '我的证书', type: 'info' },
	{ id: '/pages/application/application', name: '入会申请', type: 'info' },
	// { id: '/pagesA/user/mycard', name: '我的名片', type: 'info' },
	{ id: '/pagesB/activities/myActivitiesList', name: '我的参会', type: 'info' },
	{ id: '/pagesA/order/order', name: '我的缴费', type: 'info' },
	{ id: '/pagesA/product/product', name: '企业产品', type: 'info', need_auth: true },
	{ id: '/pages/vip/vip', name: '权益', type: 'info' },
	{ id: '/pagesB/talent-recruitment/list', name: '人才招聘', type: 'info' },
	{ id: '/pagesA/change_moments/change_moments', name: '我的发布', type: 'info' },
	{ id: '/pagesA/user/advice', name: '投诉建议', type: 'info' },
	// { id: '/pagesA/set/set', name: '设置', type: 'info' },
	{ id: '/pages/vipList/vipList?title=committee', name: '领导班子', type: 'info' },
	{ id: '/pages/branch/branch', name: '分会', type: 'info', need_auth: true },
	{ id: '/pagesA/photoAlbum/photoAlbum', name: '活动相册', type: 'info', need_auth: true },
	{ id: '/pagesA/file/file', name: '电子期刊', type: 'info', need_auth: true },
	{ id: '/pagesA/file/files', name: '文件管理', type: 'info', need_auth: true },
	{ id: '/pages/vipList/vipList?title=vip', name: '认证会员', type: 'info' },
	{ id: '/pages/expert/expert', name: '人员智库', type: 'info' },
	{ id: '/pages/navList/navList?title=introduce', name: '机构介绍', type: 'info' },
	{ id: '/pagesA/introduce/introduce', name: '机构介绍模块1', type: 'info' },
	{ id: '/pagesA/constitution/constitution', name: '机构介绍模块2', type: 'info' },
	{ id: '/pagesA/framework/framework', name: '机构介绍模块3', type: 'info' },
	{ id: '/pagesA/public/public', name: '关注公众号', type: 'info' },
];
// 导航配置
export const navigation = {
	label: '导航',
	styles: {
		background: '#fff',
	},
	list: [
		{
			id: '00001',
			icon: 'icon-shop',
			text: '首页',
			jump: {
				type: 'custom',
				id: '000000',
			},
		},
		{
			id: '00003',
			icon: 'icon-sort',
			text: '通讯录',
			jump: {
				type: 'fixed',
				id: 'category',
			},
		},
		{
			id: '00004',
			icon: 'icon-cart',
			text: '社区',
			jump: {
				type: 'fixed',
				id: 'car',
			},
		},
		{
			id: '00005',
			icon: 'icon-my',
			text: '我的',
			jump: {
				type: 'fixed',
				id: 'my',
			},
		},
	],
};

// 文章分组配置
export const goodsGroups = [
	{
		name: '新品上架',
		type: '0001',
		level: 1,
		child: [],
	},
	{
		name: '热销排行',
		type: '0002',
		level: 1,
		child: [],
	},
];

// 文章列表模板配置
export const listTpl = {
	model: 'one',
	addIcon: 'cart',
};

// 文章列表模板配置
export const categoryTpl = {
	advertising: false, // 广告位
};

// 商城初始配置
export const rojectModel = {
	name: '',
	cover: 'http://110.42.184.128:8090/img/1639553326077.jpeg',
	config: {
		navigation: navigation,
		goodsGroups: goodsGroups,
		listTpl: listTpl,
		categoryTpl: categoryTpl,
		hotList: [],
		backgroundColor: '#FFFFFF',
	},
	pages: [
		// 自定义页面集合
		{
			id: '000000',
			name: '首页',
			home: true,
			componentList: [],
		},
	],
};
