<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-01 08:57:21
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-07-01 18:22:20
-->
<template>
	<div>
		<div class="wrap pl8">
			<div class="wrap-label f13 f-grey">{{ label }}</div>
			<el-switch v-model="mValue.bind"></el-switch>
		</div>
		<div class="wrap pl8 mt12 pr0" v-if="mValue.bind">
			<div class="wrap-label f13 f-grey">文章分类</div>
			<div class="w200">
				<el-select v-model="mValue.id" placeholder="请选择">
					<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
				</el-select>
			</div>
		</div>
		<div class="wrap pl8 mt12 pr0" v-if="mValue.bind">
			<div class="wrap-label f13 f-grey">文章数量</div>
			<div class="w200">
				<el-slider v-model="num" show-input :min="0" :max="10" @change="changeNum"> </el-slider>
			</div>
		</div>
	</div>
</template>

<script>
import schemaMixin from '@/mixin/schemaMixin';
import { getGoodsType } from '@/api/goods';
export default {
	name: 'SchemaBindSwitch',

	mixins: [schemaMixin],

	props: {
		value: {
			type: Object,
			default: {},
		},
	},
	watch: {
		value: {
			immediate: true,
			deep: true,
			handler(newValue, oldValue) {
				this.getList();
			},
		},
	},
	data() {
		return {
			defaultOptions: {
				trueLabel: '显示',
				falseLabel: '隐藏',
			},
			list: [],
			num: 1,
		};
	},
	methods: {
		// 获取文章分类
		async getList() {
			let res = await getGoodsType();
			if (res.code == 200) {
				res.data.unshift({ id: 0, name: '全部' });
				let arr = [];
				function getArr(params) {
					params.forEach((element) => {
						arr.push(element);
						if (element.childlist && element.childlist.length != 0) {
							getArr(element.childlist);
						}
					});
				}
				getArr(res.data);
				this.list = arr;
				if (this.mValue.number) {
					this.num = this.mValue.number;
				}
			}
		},
		changeNum() {
			this.mValue.number = this.num;
		},
	},
};
</script>

<style lang="scss" scoped>
.wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 25px; /*no*/

	.wrap-label {
		font-size: 14px; /*no*/
		line-height: 32px; /*no*/
	}
}
.flex-right {
	display: flex;
	justify-content: flex-end;
}
</style>
