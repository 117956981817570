/*
 * @Description: template 类型组件对应
 * @Autor: WangYuan
 * @Date: 2021-10-18 11:47:02
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-18 20:50:11
 */
export default {
    number: "SchemaNumber",
    string: "SchemaString",
    select: "SchemaSelect",
    elselect: "SchemaElSelect",
    switch: "SchemaSwitch",
    color: "SchemaColor",
    upload: "SchemaUpload",
    jump: "SchemaJump",
    time: "SchemaTime",
    cube: "SchemaCapCube",
    goods: "SchemaGoods",
    object: 'SchemaObject',
    array: 'SchemaArray',
    tab:'SchemaTab',
    character:'SchemaCharacter',
    expert:'SchemaExpert',
    richtext:'SchemaRichtext',
    type:'SchemaType',
    uploadVideo:'SchemaUploadVideo',
    map:'SchemaMap',
    link:'SchemaLink',
    bindSwitch:'SchemaBindSwitch',
    file:'SchemaFile',
    files:'SchemaFiles',
    bind:'SchemaBind',
    video:'SchemaVideo',
    padding:'SchemaPadding',
    radius:'SchemaRadius',
    title:'SchemaTitle',
    tabSet:'SchemaTabSet'
}