<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-08 15:37:03
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-09-21 14:14:04
-->
<template>
  <config-item :label="label">
    <GoodsConfigList :label="label" :value="mValue" @edit="edit" @submit="replace" @update="update"></GoodsConfigList>

    <GoodsConfigChoose
      v-if="show"
      :show.sync="show"
      :label="label"
      :value="mValue"
      @submit="replace"
    >
    </GoodsConfigChoose>
  </config-item>
</template>

<script>
import ConfigItem from '../../../components/global/ConfigItem.vue'
import schemaMixin from '@/mixin/schemaMixin'
import GoodsConfigList from './GoodsConfigList.vue'
import GoodsConfigChoose from './GoodsConfigChoose.vue'
export default {
  name: 'SchemaFiles',

  components: { ConfigItem,GoodsConfigList,GoodsConfigChoose },

  mixins: [schemaMixin],

  props: {
    value: {
      type: Number,
      default: () => -1
    }
  },

  data () {
    return {
      show: false,
      mValue: []
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (newValue, oldValue) {
        this.mValue = newValue
      }
    },

    mValue: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    edit (item) {
      this.show = true
    },
    update(ids) {
      this.mValue = ids
    },
    replace (ids) {
      console.log(ids)
      this.mValue = ids
    }
  }
}
</script>

<style lang="scss" scoped></style>
