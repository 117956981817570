<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-07-27 10:56:44
-->
<template>
	<config-item :label="label">
		<div class="flex row-right">
			<div class="color-picker-border color-picker">
				<el-color-picker v-model="mValue" :predefine="predefineColors" v-bind="mOptions" show-alpha></el-color-picker>
			</div>

			<!-- <color-picker  v-model="mValue">
      </color-picker> -->
		</div>
	</config-item>
</template>

<script>
import schemaMixin from '@/mixin/schemaMixin';
import ConfigColorPicker from './ConfigColorPicker.vue';
import ColorPicker from '@/components/colorPicker';
export default {
	name: 'SchemaColor',

	mixins: [schemaMixin],

	components: {
		ConfigColorPicker,
		ColorPicker,
	},

	data() {
		return {
			predefineColors: ['#ffffff', '#f5f5f5f5', '#F2F2F2', '#FF4444', '#FFCD00', '#3FBC87', '#5197FF', '#BADCFF', '#000000'],
			gradient: {
				type: 'linear',
				degree: 0,
				points: [
					{
						left: 0,
						red: 0,
						green: 0,
						blue: 0,
						alpha: 1,
					},
					{
						left: 100,
						red: 255,
						green: 0,
						blue: 0,
						alpha: 1,
					},
				],
			},
		};
	},
	methods: {
		onChange(attrs, name) {
			console.log(name);
		},
	},
};
</script>
<style lang="scss" scoped>
//   .color-picker-border {
//   border: 1px solid #c0c4cc;
//   border-radius: 4px;
//   display: flex;
//   align-items: center;
//   padding: 2px;
// }
// .color-picker {
//   background: #f8f8f8;
//   margin-top: 12px;
//   border-radius: 4px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   overflow: hidden;
// }
.color-picker ::v-deep .el-color-picker__trigger .el-color-picker__color .el-color-picker__color-inner,
.color-picker ::v-deep .el-color-picker__trigger .el-color-picker__color,
.color-picker ::v-deep .el-color-picker__trigger,
.color-picker ::v-deep .el-color-picker {
	width: 60px;
	height: 32px;
	padding: 0;
}
</style>
