<!--
 * @Description: 页面跳转选择组件
 * @Autor: WangYuan
 * @Date: 2021-08-12 11:06:37
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-09 10:51:21
-->

<template>
	<div class="flex col-center h25">
		<!-- <div class="mr15">链接</div> -->
		<template>
			<div v-if="!mValue.id" class="f12 f-theme pointer" @click="open">
				选择跳转到的页面
			</div>

			<template v-else>
				<el-tag size="small">{{ pageName }}</el-tag>
				<span class="ml5 f12 f-theme pointer" @click="open">修改</span>
			</template>
		</template>

		<JumpDialog ref="jump"></JumpDialog>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
	name: 'JumpSelect',
	provide() {
		return {
			value: this.value,
		};
	},
	props: {
		value: {
			default: () => ({}),
		},
	},
	data() {
		return {
			mValue: null,
		};
	},
	computed: {
		...mapGetters(['project', 'fixedPages', 'infoPages']),
		pageName() {
			let page = null;
			switch (this.mValue.type) {
				case 'fixed':
					page = this.fixedPages.find((page) => page.id == this.mValue.id);
					break;
				case 'custom':
					page = this.project.pages.find((page) => page.id == this.mValue.id);
					if (!page) {
						page = { name: '链接已失效' };
					}
					break;
				case 'wxapp':
					page = { name: '活动小程序' };
					if (this.mValue.id != 'huique') {
						page.name = '微信小程序';
					}
					break;
				case 'event':
					page = { name: '活动详情页' };
					break;
				case 'event_xieban':
					page = { name: '活动详情页' };
					break;
				case 'vote':
					page = { name: '投票详情页' };
					break;
				case 'form':
					page = { name: '表单/问卷' };
					break;
				case 'info':
					page = this.infoPages.find((page) => page.id == this.mValue.id);
					if (!page) {
						page = { name: '文章' };
					}
					if (this.mValue.id == '/customerService') {
						page = { name: '小程序客服' };
					}
					break;
				case 'link':
					page = { name: '外链h5' };
					break;
				case 'photo':
					page = { name: '相册详情页' };
					break;
				case 'wx_video':
					page = { name: '微信视频号' };
					break;
				default:
					break;
			}
			return page.name;
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(newValue, oldValue) {
				this.mValue = newValue;
				console.log('mValue');
				console.log(this.mValue);
			},
		},
		mValue: {
			immediate: true,
			handler(newValue, oldValue) {
				this.$emit('input', newValue);
			},
		},
	},
	methods: {
		open() {
			this.$refs.jump.open();
		},

		getName() {
			console.log('触发getName');
		},
	},
};
</script>

<style lang="scss" scoped></style>
