<!--
 * @Description: 页面跳转选择组件
 * @Autor: WangYuan
 * @Date: 2021-08-12 11:06:37
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-08 10:48:29
-->
<template>
	<config-item :label="label">
		<div class="flex col-center h32">
			<div v-if="!mValue.id" class="f12 f-theme pointer" @click="open"> 选择跳转到的页面 </div>
			<template v-else>
				<!-- {{value}} -->
				<el-tag size="small">{{ pageName }}</el-tag>
				<span class="ml5 f12 f-theme pointer" @click="open">修改</span>
			</template>
		</div>
		<JumpDialog ref="jump" :father="father"></JumpDialog>
	</config-item>
</template>
<script>
import schemaMixin from '@/mixin/schemaMixin';
import { mapGetters } from 'vuex';

export default {
	name: 'SchemaJump',

	mixins: [schemaMixin],

	provide () {
		return {
			value: this.value,
		};
	},

	props: {
		value: {
			default: () => ({}),
		},
		father: {
			default: () => '',
		},
	},
	computed: {
		...mapGetters(['project', 'fixedPages', 'infoPages']),
		pageName () {
			let page = null;
			switch (this.mValue.type) {
				case 'fixed':
					page = this.fixedPages.find((page) => page.id == this.mValue.id);
					break;
				case 'custom':
					page = this.project.pages.find((page) => page.id == this.mValue.id);
					if (!page) {
						page = { name: '链接已失效' };
					}
					break;
				case 'wxapp':
					page = { name: '活动小程序' };
					if (this.mValue.id != 'huique') {
						page.name = '微信小程序';
					}
					break;
				case 'event':
					page = { name: '活动详情页' };
					break;
				case 'event_xieban':
					page = { name: '活动详情页' };
					break;
				case 'vote':
					page = { name: '投票详情页' };
					break;
				case 'form':
					page = { name: '表单/问卷' };
					break;
				case 'info':
					page = this.infoPages.find((page) => page.id == this.mValue.id);
					if (!page) {
						page = { name: '文章' };
					}
					if (this.mValue.id == '/customerService') {
						page = { name: '小程序客服' };
					}
					break;
				case 'link':
					page = { name: '外链h5' };
					break;
				case 'photo':
					page = { name: '相册详情页' };
					break;
				case 'wx_video':
					page = { name: '微信视频号' };
					break;
				default:
					break;
			}
			return page.name;
		},
	},

	methods: {
		open () {
			// if(Array.isArray(this.mValue)){
			//   let obj = Object.assign({}, this.mValue)
			//   this.mValue = obj
			// }
			// if(!this.mValue.id){
			//   this.$refs.jump.clear();
			// }
			this.$refs.jump.reset(this.value);
			this.$refs.jump.open();
		},
	},
};
</script>
<style lang="scss" scoped></style>
