<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-08-19 15:10:03
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-07-20 15:40:50
-->
<template>
  <div class="flex flex-wrap row-between">
     <el-select v-model="value" placeholder="请选择" @change="change">
      <el-option
        v-for="item in list"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getGoodsType } from "@/api/goods";

export default {
  name: "GoodsConfigList",
  props: ["value"],

  data() {
    return {
      mValue: 0,
      list: [],
    };
  },

  computed: {
    ...mapGetters(["project"]),
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
       
      },
    },
  },
  created () {
     this.getList();
  },

  methods: {
    // 获取文章分类
    async getList() {
      let res  = await getGoodsType();
      if(res.code==200){
        this.list=[]
        this.getTreeData(res.data)
        this.list.unshift({id:0,name:'全部'})
      }
    },
    getTreeData(data){
                // 循环遍历json数据
                for(var i=0;i<data.length;i++){
                    
                    if(data[i].childlist.length<1){
                        // children若为空数组，则将children设为undefined
                        // data[i].childlist=undefined;
                        this.list.push(data[i])
                    }else {
                        this.list.push(data[i])
                        // children若不为空数组，则继续 递归调用 本方法
                        this.getTreeData(data[i].childlist);
                    }
                }
      // return data;
    },
    change(){
      this.$emit("submit",this.value);
    },
    getName(){
      let name=''
      name = this.list.find((item)=> item.id==this.value).name
      console.log(this.list,this.value)
      return name
    },
    add() {
      this.$emit("edit");
    },
  },
};
</script>
