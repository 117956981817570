<!--
 * @Description: 属性组件配置模板
 * @Autor: WangYuan
 * @Date: 2021-09-23 20:10:52
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-11 15:17:59
-->
<template>
	<ul>
		<li v-for="(s, key, index) in schema" :key="index">
			<div class="border f14 pt18 mt12" v-if="s.border">背景样式</div>
			<component :key="index" :is="typeToComponent[s.type]" v-model="value[key]" v-bind="s" :schema="s">
				<custom-schema-template v-if="s.child" :schema="s.child" :showAdd="showAdd" :father="s.father" :value="value[key]" :tips="s.tips"></custom-schema-template>
			</component>
		</li>
	</ul>
</template>

<script>
import typeToComponent from '@/config/schema-template';

export default {
	name: 'custom-schema-template',

	provide() {
		return {
			mode: this,
		};
	},

	props: {
		label: {
			typeof: String,
			default: '',
		},
		border: {
			typeof: Boolean,
			default: () => false,
		},
		schema: {
			typeof: Object,
			default: () => {},
		},
		value: {
			typeof: Object,
			default: () => {},
		},
		id: {
			typeof: String,
			default: '',
		},
	},

	data() {
		return {
			typeToComponent,
		};
	},
};
</script>
<style lang="scss" scoped>
.border {
	border-top: 1px solid #ebedf0;
}
</style>
