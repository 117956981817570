<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-17 14:53:47
-->
<template>
  <div>
    <config-item label="字重">
      <div class="pt10">
        <el-checkbox v-model="cloneValue.bold"><span class="f12">加粗</span></el-checkbox>
      </div>
    </config-item>
     <config-item label="对齐方式">
      <div class="flex">
         <el-radio-group v-model="cloneValue.align" size="mini">
          <el-radio-button label="left">居左</el-radio-button>
          <el-radio-button label="center">居中</el-radio-button>
        </el-radio-group>
      </div>
    </config-item>
     <config-item label="指示器图标">
       <div class="pt10 flex row-between">
        <div class="f12 f-grey">建议上传30px*10px图片</div>
        <Imgpond v-model="cloneValue.lineImg" :count="1" />
       </div>
    </config-item>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaTabSet",
  mixins: [schemaMixin],
  data() {
    return {
      cloneValue:{}
    };
  },
  created () {
    if(this.mValue){
      this.cloneValue=this.$cloneDeep(this.mValue)
    }else{
      this.cloneValue={
        bold:false,
        align:'center',
        lineImg:''
      }
    }
    
  },
  watch: {
    "cloneValue": {
      handler(val) {
        this.mValue=val
      },
      deep: true,
    },
  },
  methods: {
    changeDisabled(e){
      this.$emit('changeDisabled',e)
    },
    changeValue(){
      this.mValue=this.cloneValue
    },
  }
};
</script>
<style lang="scss" scoped>
.title-status ::v-deep .el-radio{
  margin-right: 10px;
}
.title-status ::v-deep .el-radio__label{
  font-size: 12px;
}
</style>