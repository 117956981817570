<!--
 * @Author: Tan Chao
 * @Date: 2022-05-17 14:58:17
 * @LastEditTime: 2022-09-20 14:10:33
-->
<template>
<div>
    <config-item :label="label">
    <GoodsConfigList :value="mValue" @edit="edit" @submit="replace"></GoodsConfigList>
  </config-item>
</div>
 
</template>

<script>
import ConfigItem from '../../../components/global/ConfigItem.vue'
import schemaMixin from '@/mixin/schemaMixin'
import GoodsConfigList from './GoodsConfigList.vue'
import { mapGetters } from "vuex";
export default {
  name: 'SchemaBind',

  components: { ConfigItem,GoodsConfigList },

  mixins: [schemaMixin],

  props: {
    value: {
      type: Number,
      default: () => 0
    },
    id:{
      type:String,
      default:()=> ''
    }
  },
  computed: {
    ...mapGetters(["curPage"]),
  },
  data () {
    return {
      show: false,
      mValue: 0
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue, oldValue) {
        this.mValue = newValue
      }
    },

    mValue: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    edit (item) {
      this.show = true
    },

    replace (ids) {
      console.log(ids)
      this.mValue = ids
    }
  }
}
</script>

<style lang="scss" scoped></style>
